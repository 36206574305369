.section-title {
	margin-bottom: 20px;
	font-family: "Patrick Hand", cursive;
    font-weight: 400;
    font-style: normal;
	
	@include media-query(767px) {
		margin-bottom: 40px;
	}

	> span {
		font-weight: 700;
		color: #78c3e0;
		text-transform: uppercase;
		letter-spacing: 1px;
		display: block;
    	margin-top: -0.3em;
		font-size: 30px;
	}

	h2 {
		font-size: 48px;
		font-size: calc-rem-value(48);
		line-height: 1em;
		margin: 0.2em 0 0;

		span {
			-webkit-text-fill-color: $white; /* Will override color (regardless of order) */
			-webkit-text-stroke-width: 2px;
			-webkit-text-stroke-color: $heading-color;
		}

		@include media-query(991px) {
			font-size: 40px;
			font-size: calc-rem-value(40);
		}

		@include media-query(767px) {
			font-size: 35px;
			font-size: calc-rem-value(35);
		}
	}
}

.section-title-s2 {
	@extend .section-title;
	margin-bottom: 40px;
	
	@include media-query(767px) {
		margin-bottom: 40px;
	}
}
.testo-paragraph {
    white-space: pre-line;
}

.section-title-s3 {
	@extend .section-title-s2;
	margin-bottom: 40px;
	font-size: 22px;

	h2 {
		margin-bottom: 0.4em;
	}
	
	@include media-query(767px) {
		margin-bottom: 40px;
	}
}