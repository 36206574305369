/*--------------------------------------------------------------
#0.6	target-area
--------------------------------------------------------------*/
.target-area {

	.target-content {
		@include widther(1200px) {
			padding-left: 70px;	
			padding-top: 37px;		
		}

		@include media-query(991px) {
			margin-top: 60px;
		}
	}

	.goal-raised {
		overflow: hidden;
		margin-top: 50px;
		margin-bottom: 65px;
		border-bottom: 1px solid #d1d6de;

		@include media-query(767px) {
			margin-top: 30px;
			margin-bottom: 45px;
		}

		> div {
			float: left;
		}

		> div + div {
			margin-left: 35px;
			padding-left: 35px;
			padding-bottom: 35px;
			border-left: 1px solid #d1d6de;
		}
	}

	.goal-raised span {
		font-size: 14px;
		font-size: calc-rem-value(14);
		font-weight: 600;
		text-transform: uppercase;

		@include media-query(767px) {
			font-size: 13px;
			font-size: calc-rem-value(13);
		}
	}

	.goal-raised h3 {
		font-size: 36px;
		font-size: calc-rem-value(36);
		margin: 0.4em 0 0;

		@include media-query(991px) {
			font-size: 30px;
			font-size: calc-rem-value(30);
		}

		@include media-query(767px) {
			font-size: 27px;
			font-size: calc-rem-value(27);
		}
	}
}


/*--------------------------------------------------------------
#0.7	causes-section
--------------------------------------------------------------*/
.causes-section {
	padding-top: 0;
	background-color: #efefef!important;

	.container-fluid {
		padding: 0;
	}

	.inner {
		border-radius: 20px!important;
		position: relative;
	}

	.overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		border-radius: 20px!important;

		&:before {
			border-radius: 20px;

			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			background: linear-gradient(to bottom, transparent 50%, $black 70%);
			@include transition-time(0.2s);
		}
	}

	.overlay-content {
		border-radius: 20px;

		padding: 50px;
		position: absolute;
		bottom: -20px;
		@include transition-time(0.5s);

		@include media-query(1360px) {
			padding: 50px 30px;
		}
	}

	.img-holder img {
		
		width: 100%;
		border-radius: 20px!important;
	}

	.slick-slider {
		.slick-slide {
			padding: 10px;

			border-radius: 20px;

			@include media-query(1399px) {
				padding: 0;
			}
		
		}
	}


	.progress {
		background-color: #e6e6e6;
		height: 8px;
		box-shadow: none;
		overflow: visible;
		box-shadow: none;
		position: relative;
		top: -50px;
		border-radius: 3px;
		opacity: 0;
		@include transition-time(0.5s);
		margin-bottom: 20px;

		span {
			background-color: $white;
			font-size: 14px;
			font-size: calc-rem-value(14);
			font-weight: bold;
			color: $text-color;
			padding: 2px 6px;
			text-align: center;
			position: absolute;
			right: -15px;
			top: -32px;

			&:before {
				content: "";
				width: 0;
				height: 0;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 10px solid $white;
				position: absolute;
				bottom: -6px;
				left: 14px;
			}

			@include media-query(991px) {
				padding: 0 6px;
			}
		}
	}

	.progress-bar {
		background: $theme-primary-color;
		position: relative;
		box-shadow: none;
	    border-radius: 3px;
		overflow: visible;
	}

	.inner h3 {
		font-size: 22px;
		font-size: calc-rem-value(22);
		line-height: 1.3em;
		margin: 0 0 0.8em;

		@include media-query(1199px) {
			font-size: 18px;
			font-size: calc-rem-value(18);
		}

		a {
			color: $white;
		}
	}

	.goal-raised {
		opacity: 0;
		visibility: hidden;
		@include transition-time(0.5s);

		span {
			color: $white;

			@include media-query(1199px) {
				font-size: 14px;
				font-size: calc-rem-value(14);
			}
		}

		a {
			font-size: 13px;
			font-weight: 700;
			color: $theme-primary-color;
			text-transform: uppercase;
			float: right;

			.fi:before {
				font-size: 14px;
				font-size: calc-rem-value(14);				
			}

			i {
				position: relative;
				left: -6px;
				top: 2px;
			}
		}
	}

	.overlay:hover:before {
		background-image: linear-gradient(to bottom, transparent 30%, transparentize($theme-primary-color, 0.6) 50%, $black 70%);
	}

	.overlay:hover .goal-raised {
		opacity: 1;
		visibility: visible;
	}

	.overlay:hover .overlay-content {
		bottom: 0;
	}

	.overlay:hover .progress  {
		top: 0;
		opacity: 1;
	}

}

.causes-section-pg {
	border-radius: 20px;

    padding: 120px 0;

    @include media-query(991px) {
        padding: 100px 0;
    }

    @include media-query(767px) {
        padding: 80px 0;
    }
}


/*--------------------------------------------------------------
#0.8	about-section
--------------------------------------------------------------*/
.about-section {
	padding-top: 0;
	padding-bottom: 0;
	overflow: hidden;

	.left-col {
		width: calc(50% + 115px);
		float: left;

		@include media-query(991px) {
			width: 100%;
			float: none;
		}

		.about-area {
			max-width: 700px;
			margin-left: auto;
			padding-left: 15px;
			padding-right: 100px;
			padding-bottom: 70px;

			@include media-query(991px) {
				max-width: 100%;
				padding-right: 15px;

			}
		}
	}

	.right-col {
		width: calc(50% - 115px);
		float: right;
		background-size: cover;
		background-position: center center;
		height: 823px;

		@include media-query(991px) {
			float: none;
			width: 100%;
			height: 500px;
		}
	}

	.btns {
		padding-top: 15px;

		> a:last-child {
			margin-left: 25px;
		}
	}

	.about-features {
		overflow: hidden;
		margin-top: 45px;

		> div {
			width: 50%;
			float: left;
			padding-left: 65px;
			padding-right: 15px;
			position: relative;
			margin-bottom: 35px;

			@include media-query(991px) {
				padding-left: 55px;
			}

			@include media-query(767px) {
				
			}

			@include media-query(550px) {
				width: 100%;
				float: none;
				margin-bottom: 25px;
			}
		}

		.icon {
			position: absolute;
			left: 0;
			top: -7px;

			.fi:before {
				font-size: 50px;
				font-size: calc-rem-value(50);

				@include media-query(991px) {
					font-size: 40px;
					font-size: calc-rem-value(40);
				}
			}
		}

		h3 {
			font-size: 22px;
			font-size: calc-rem-value(22);
			margin: 0 0 0.4em;

			@include media-query(991px) {
				font-size: 20px;
				font-size: calc-rem-value(20);
			}
		}

		p {
			font-size: 14px;
			font-size: calc-rem-value(14);
			margin: 0;
		}
	}
}


/*--------------------------------------------------------------
#0.9	events-section
--------------------------------------------------------------*/
.events-section {
	padding-bottom: 0;
	position: relative;
	z-index: 2;

	.events-slider-holder {
		@include widther(767px) {
			margin-right:-85%;
			margin-left:-15px
		}
	}

	.slick-slide {
		@include widther(767px) {
			padding: 0 15px
		}
	}

	.grid {
		position: relative;

		&:before {
			content: "";
			width: 100%;
			height: 110%;
			position: absolute;
			left: 0;
			top: 10%;
			background-image: linear-gradient(to bottom, transparent 40%, $black 60%);
			@include transition-time(0.5s);
		}


		.details {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	.details .inner {
		width: 100%;
		padding: 40px;
		position: absolute;
		left: 0;
		bottom: 0;
	}

	.inner h3 {
		font-size: 20px;
		font-size: calc-rem-value(20);
		margin: 0 0 0.3em;

		a {
			color: $white;
		}

		a:hover {
			color: $theme-primary-color;
		}
	}

	.inner p {
		font-size: 14px;
		font-size: calc-rem-value(14);
		font-weight: 700;
		color: $theme-primary-color;
		margin: 0;
	}

	.events-slider-item:focus {
		outline: none;
	}

	/*** slider arrows ***/
	.events-slider-nav {
		position: absolute;
		bottom: 160px;
		left: -8px;

		@include media-query(991px) {
			position: relative;
			bottom: 15px;
			left: 0;
		}

		.slider-prev {
			margin-bottom: 8px;
		}
	}

	.slick-arrow {
		background-color: $white;
	    width: 80px;
	    height: 80px;
	    line-height: 45px;
	    padding: 0;
		text-align: center;
		cursor: pointer;
		border-radius: 50%;
		box-shadow: 5px 27px 29px transparentize($black, 0.7);
		z-index: 11;
		top: 40%;

		@include media-query(1199px) {
			margin: 5px;
		}

		@include media-query(991px) {
			float: left;
			display: none!important;
		}

		.fi:before {
			font-size: 20px;
			font-size: calc-rem-value(20);
			color: $theme-primary-color;
		}

		&.slick-prev {
			left: 0;
            &:before {
                font-family: "themify";
                content: "\e629";
                opacity: 1;
				color: $theme-primary-color;
				line-height: unset;
            }
        }

        &.slick-next {
			left: 0;
			top: 54%;
            &:before {
                font-family: "themify";
                content: "\e628";
                opacity: 1;
				color: $theme-primary-color;
				line-height: unset;
            }
        }
	}

	.grid:hover:before {
		top: 0;
		background-image: linear-gradient(to bottom, transparent 30%, transparentize($theme-primary-color, 0.6) 50%, $black 60%);
	}
}

.events-pg-section {
	padding-bottom: 120px;

	@include media-query(991px) {
		padding-bottom: 100px;
	}

	@include media-query(767px) {
		padding-bottom: 80px;
	}
}


/*--------------------------------------------------------------
#1.0	partner-section
--------------------------------------------------------------*/
.partner-section {
	@include background-style("../../images/partners/partners-bg.jpg", center center, cover, no-repeat, local);
	position: relative;
	padding: 130px 0 205px;
	margin-top: -60px;

	@include media-query(1199px) {
		margin-top: 90px;
		padding: 100px 0;
	}

	@include media-query(991px) {
		margin-top: 90px;
		padding: 70px 0;
	}

	&:before {
		content: "";
		background-color: transparentize(#1d1d1d, 0.05);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.owl-carousel .owl-item img {
		width: auto;
		max-width: 100%;
		display: inline-block;
	}

	.partners-slider > div {
		text-align: center;
	}
}


/*--------------------------------------------------------------
#1.1	cta-s1-section
--------------------------------------------------------------*/
.cta-s1-section {
	margin-top: -105px;
	padding-bottom: 120px;
	position: relative;

	@include media-query(1199px) {
		margin-top: 0;
		padding: 120px 0;
	}

	@include media-query(991px) {
		padding: 100px 0;
	}

	@include media-query(767px) {
		padding: 80px 0;
	}

	.info-col {
		padding-top: 220px;

		@include media-query(1199px) {
			padding-top: 50px;
		}
	}

	.details p {
		margin-bottom: 2.5em;
	}
	.radius-team{
		border-radius: 50%;
	}

	.img-holder {
		margin-right: -30px;

		img {
			width: 100%;
		}

		@include media-query(1199px) {
			margin-right: 0;
		}
	}
}


/*--------------------------------------------------------------
#1.2	cta-s2-section
--------------------------------------------------------------*/
.cta-s2-section {
	@include background-style("../../images/cta-s2-bg.jpg", center top, cover, no-repeat, local);
	padding: 220px 0;

	@include media-query(991px) {
		text-align: center;
		padding: 80px 0;
	}

	h3 {
		font-size: 48px;
		font-size: calc-rem-value(48);
		color: $white;
		margin: 0;
		text-shadow: 1px 1px 5px rgba(0,0,0, 0.5);

		@include media-query(991px) {
			font-size: 30px;
			font-size: calc-rem-value(30);
		}
	}

	.video-holder {
		float: right;
		margin-top: 30px;

		@include media-query(991px) {
			float: none;
		}

		.video-btn {
			background-color: $theme-primary-color;
			width: 85px;
			height: 85px;
			border-radius: 85px;
			text-align: center;
			line-height: 85px;
			display: inline-block;
			position: absolute;
			-webkit-animation: spineer 2s infinite;
			animation: spineer 2s infinite;
			border: 0;

			@include media-query(767px) {
				width: 55px;
				height: 55px;
				line-height: 55px;
				border-radius: 55px;
				position: relative;
			}

			.fi:before {
				font-size: 30px;
				color: $white;
				font-weight: bold;

				@include media-query(767px) {
					font-size: 20px;
				}
			}

			&:after {
				content: "";
				width: 75px;
				height: 75px;
				border-radius: 75px;
				display: inline-block;
				position: absolute;
				left: 50%;
				top: 50%;
				@include center-by-translating();
				-webkit-animation: spineer 2s 1.5s infinite;
				animation: spineer 2s 1.5s infinite;

				@include media-query(767px) {
					width: 55px;
					height: 55px;
					border-radius: 55px;
				}
			}

		}

		@-webkit-keyframes spineer {
			from {
				box-shadow: 0 0 0 0 transparentize($theme-primary-color, 0.01);
			}

			to {
				box-shadow: 0 0 0 45px transparentize($theme-primary-color, 0.99);
			}
		}

		@keyframes spineer {
			from {
				box-shadow: 0 0 0 0 transparentize($theme-primary-color, 0.01);
			}

			to {
				box-shadow: 0 0 0 45px transparentize($theme-primary-color, 0.99);
			}
		}

		.fi:before {
			font-size: 30px;
			font-size: calc-rem-value(30);
			color: $white;

			@include media-query(991px) {
				font-size: 30px;
				font-size: calc-rem-value(30);
			}
		}
	}
}


/*--------------------------------------------------------------
#1.3	blog-section
--------------------------------------------------------------*/
.blog-section {
	padding-bottom: 90px;

	@include media-query(991px) {
		padding-bottom: 70px;
	}

	@include media-query(767px) {
		padding-bottom: 65px;
	}

	.blog-grids {
		margin: 30px -15px 0;

		@include media-query(767px) {
			margin: 0 -7px;
		}

		.grid {
			width: calc(33.33% - 30px);
			float: left;
			margin: 0 15px 30px;
			position: relative;

			&:before {
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				background-image: linear-gradient(to bottom, transparent 50%, $black 70%);
			}

			@include media-query(991px) {
				width: calc(50% - 30px);
			}

			@include media-query(767px) {
				width: calc(50% - 15px);
				margin: 0 7px 15px;
			}

			@include media-query(600px) {
				width: calc(100% - 15px);
				float: none;
			}
		}

	}

	.cat {
		position: absolute;
		left: 35px;
		top: 35px;

		@include media-query(767px) {
			left: 15px;
			top: 15px;
		}

		a {
			background-color: $theme-primary-color;
			padding: 5px 10px;
			font-size: 14px;
			font-size: calc-rem-value(14);
			color: $white;
			text-align: center;
			display: inline-block;
			border-radius: 5px;
		}
	}

	.entry-details {
		position: absolute;
		left: 35px;
		bottom: 35px;
		padding-right: 35px;

		@include media-query(767px) {
			left: 15px;
			bottom: 15px;
			padding-right: 15px;
		}

		h3 {
			font-size: 20px;
			font-size: calc-rem-value(20);
			margin: 0 0 0.5em;
			line-height: 1.3em;

			@include media-query(767px) {
				font-size: 18px;
				font-size: calc-rem-value(18);
			}
		}

		h3 a {
			color: $white;
		}

		h3 a:hover {
			color: $theme-primary-color;
		}

		p {
			font-size: 14px;
			font-size: calc-rem-value(14);
			font-weight: 700;
			color: $theme-primary-color;
			margin: 0;
			text-transform: uppercase;
			letter-spacing: 5px;

			@include media-query(767px) {
				font-size: 13px;
				font-size: calc-rem-value(13);
			}
		}
	}
}


/*--------------------------------------------------------------
#1.4	instagram-section
--------------------------------------------------------------*/
.instagram-section {
	display: none;
	.instagram-grids {
		overflow: hidden;

		.grid {
			width: 10%;
			float: left;
			position: relative;

			@include media-query(991px) {
				width: 20%;
			}
			
			a {
				width: 100%;
				display: inline-block;
				position: relative;


				&:before {
					content: "";
					background-color: transparentize($theme-primary-color, 0.3);
					width: 100%;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					@include transition-time(0.3s);
					opacity: 0;
				}

				&:after {
					font-family: "Themify";
					content: "\e73d";
					font-size: 20px;
					font-size: calc-rem-value(20);
					color: $white;
					position: absolute;
					left: 50%;
					top: 50%;
					@include center-by-translating();
					@include transition-time(0.3s);
					opacity: 0;
				}

			}

			a:hover:before {
				opacity: 1;
			}

			a:hover:after {
				opacity: 1;
			}
		}
	}
}
