@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,600');

$inputHeight: 70px;
$primaryColor: #78c3e0;


.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap:50px;
}

h1 {
  font-size: 2.5rem;
  font-weight: 300;
  letter-spacing: 0.025em;
  
  strong {
    font-weight: 400;
  }
}

.form-css {
  font-family: 'Lemon-sans';
  position: relative;
  display: block;
  height: $inputHeight;
  border-radius: 0.5*$inputHeight;
  background: white;
  margin: 0 auto;
  box-shadow: 0 8px 32px rgba(0,0,0,.28);
  overflow: hidden;
  
  input {
    line-height: $inputHeight;
    padding: 0;
    border: none;
    outline: none;
   
    font-weight: 300;
    background: #eee;
    letter-spacing: 0.02em;
    
    &:not([type=submit]) {
      width: 100%;
      display: block;
      padding: 0 200px 0 30px;
    }
    
    &:not([type=email]) {
      position: absolute;
      height: $inputHeight - 20px;
      line-height: $inputHeight - 20px;
      top: 10px;
      right: 10px;
      border-radius: 0.5*($inputHeight - 20px);
      padding: 0 25px;
      background: lighten($primaryColor, 5%);
      color: white;
      cursor: pointer;
      
      &:hover {
        background: darken($primaryColor, 10%);
      }
    }
    
    &:focus {
      outline: none;
      background: white;
    }
  }
}