.form-container {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Label styling */
  .form-label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-weight: 600;
  }
  
  /* Input and Textarea styling */
  .form-input,
  .form-textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s;
  }
  
  .form-input:focus,
  .form-textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* Submit button styling */
  .form-submit {
    cursor: pointer;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .form-submit:hover {
    background-color: #0056b3;
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .form-container {
      padding: 10px;
    }
  
    .form-label {
      margin-bottom: 2px;
    }
  
    .form-input,
    .form-textarea {
      margin-bottom: 15px;
    }
  }