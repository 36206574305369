/*--------------------------------------------------------------
	#home-style-2
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#2.1	feature-section
--------------------------------------------------------------*/
.feature-section {
	padding-bottom: 85px;

	@include widther(992px) {
		margin-top: -50px;
	}

	@include media-query(991px) {
		padding: 100px 0 70px;
	}

	@include media-query(767px) {
		padding-top: 80px 0 65px;
	}

	.feature-grids {
		margin: 0 -15px;

		@include media-query(767px) {
			margin: 0 -7px;
		}

		.grid {
			background-color: $white;
			width: calc(16.66% - 30px);
			text-align: center;
			float: left;
			margin: 0 15px 30px;
			padding: 34px 20px 40px;
			position: relative;
			box-shadow: 0px 0px 21.17px 7.83px rgba(0, 0, 0, 0.1);
			border-radius: 5px;



			@include media-query(991px) {
				width: calc(33.33% - 30px);
			}

			@include media-query(767px) {
				width: calc(33.33% - 15px);
				margin: 0 7px 15px;
			}

			@include media-query(600px) {
				width: calc(50% - 15px);
			}
		}

		.fi:before {
			font-size: 50px;
			font-size: calc-rem-value(50);
			color: $theme-primary-color;

			@include media-query(767px) {
				font-size: 40px;
				font-size: calc-rem-value(40);
			}
		}

		h3 {
			font-size: 17px;
			font-size: calc-rem-value(17);
			margin: 0;

			@include media-query(767px) {
				font-size: 15px;
				font-size: calc-rem-value(15);
			}
		}

	}
}

.causes-section-s2 .section-title-s2 {
	clear: both;
}


/*--------------------------------------------------------------
#2.2	about-section-s2
--------------------------------------------------------------*/
.about-section-s2 {
	position: relative;
	z-index: 1;

	.section-title h2 {
		margin-bottom: 1em;
	}

	.about-area img {
		display: inline-block;
		margin-top: 45px;
	}

	.fun-fact-grids > .grid + .grid {
		margin-top: 25px;
	}

	.fun-fact-area {
		margin-top: 100px;

		@include media-query(991px) {
			margin-top: 80px;
		}

		.info {
			overflow: hidden;

			@include media-query(991px) {
				max-width: 70%;
				margin: 0 auto;
			}

			@include media-query(550px) {
				max-width: 100%;
			}
		}

		.info > div {
			width: 50%;
			float: left;
		}

		.info > div:last-child {
			padding-left: 45px;

			@include media-query(550px) {
				padding-left: 0;
			}
		}

		h3 {
			font-size: 65px;
			font-size: calc-rem-value(65);
			margin: 0;
			width: 200px;
			text-align: right;
			-webkit-text-fill-color: $white;
			-webkit-text-stroke-width: 2px;
			-webkit-text-stroke-color: $heading-color;

			@include media-query(767px) {
				font-size: 45px;
				font-size: calc-rem-value(45);
			}

			@include media-query(550px) {
				font-size: 40px;
				font-size: calc-rem-value(40);
				text-align: left;
			}
		}

		p {
			font-size: 16px;
			font-size: calc-rem-value(16);
			font-weight: 600;
			margin: 35px 0 0 0;
			border-bottom: 2px solid $text-color;
			text-transform: uppercase;

			@include media-query(767px) {
				font-size: 14px;
				font-size: calc-rem-value(14);
				margin: 15px 0 0;
			}
		}
	}

	.odometer-value {
		font-family: $heading-font;
	}
}


/*--------------------------------------------------------------
#2.3	partner-section-s2
--------------------------------------------------------------*/
.partner-section-s2 {
	@include background-style("../../images/partners/partners-bg.jpg", center center, cover, no-repeat, local);
	position: relative;
	padding: 215px 0 90px;
	margin-top: -120px;

	@include media-query(991px) {
		margin-top: 90px;
		padding: 80px 0 55px;
	}

	&:before {
		content: "";
		background-color: transparentize(#1d1d1d, 0.05);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.section-title span,
	.section-title h2,
	.partner-details p {
		color: $white;
		position: relative;
	}

	.partners-area {
		@include media-query(991px) {
			margin-top: 35px;
		}
	}

	.partners-area div {
		width: 33.33%;
		float: left;
		text-align: center;
		margin-bottom: 25px;
		position: relative;

		img {
			display: inline-block;
		}
	}
}


/*--------------------------------------------------------------
#2.4	team-section
--------------------------------------------------------------*/
.team-section {
	.first-row .grid,
	.sec-row .grid {
		min-height: 1px;
		float: left;
		position: relative;

		@include media-query(991px) {
			width: 50%;
		}

		@include media-query(500px) {
			width: 100%;
			float: none;
		}
	}

	.first-row > .grid:first-child {
		@include media-query(1500px) {
			display: none;
		}
	}

	.first-row > .grid:nth-child(2) {
		text-align: right;

		@include media-query(1500px) {
			width: 50%;
		}

		@include media-query(1150px) {
			width: 50%;
		}

		@include media-query(991px) {
			width: 100%;
			margin-bottom: 45px;
			text-align: center;
		}
	}

	.team-details {
		p {
			margin-bottom: 2em;
		}
	}

	.member-info {
		background-color: transparentize($white, 0.1);
		width: 100%;
		padding: 35px 15px;
		text-align: center;
		position: absolute;
		left: 0;
		bottom: -20px;
		opacity: 0;
		visibility: hidden;
		@include transition-time(0.3s);

		h4 {
			font-family: $base-font;
			font-size: 20px;
			font-size: calc-rem-value(20);
			margin: 0 0 0.3em;


			a {
				color: $heading-color;
			}

			a:hover {
				color: $theme-primary-color;
			}

		}

		p {
			font-size: 14px;
			font-size: calc-rem-value(14);
			margin: 0;
			color: #949494;
		}

		ul {
			display: inline-block;
			overflow: hidden;
			margin-top: 10px;

			li {
				float: left;
			}

			> li + li {
				margin-left: 8px;
			}

			a {
				background-color: #a5a5a5;
				width: 32px;
				height: 32px;
				line-height: 34px;
				color: #fff;
				display: inline-block;
				text-align: center;
				font-size: 11px;
				font-size: calc-rem-value(11);
				border-radius: 50%;
			}

			a:hover {
				background-color: #516a64;
				color: $white;
			}
		}
	}

	.grid:hover .member-info {
		opacity: 1;
		visibility: visible;
		bottom: 0;
	}

	.grid:hover .member-info ul {

	}
}