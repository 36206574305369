/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;600;700&display=swap');

@font-face {
  font-family: 'AbrilTitling';
  src: url(../../fonts/AbrilTitling.otf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'AbrilTitling';
  src: url(../../fonts/AbrilTitling.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

// fonts
$base-font-size: 16;
$base-font: 'Heebo', sans-serif;
$heading-font: 'AbrilTitling';



// color
$dark-gray: #2a2a2a;
$white: #fff;
$black: #000;
$red: #78c3e0;
$black2: #020202;
$black3: #1c1c1c;

$theme-primary-color: $red;
$body-bg-color: $white;
$section-bg-color: #edecec;
$text-color: $black3;
$heading-color: $black2;
