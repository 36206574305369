/* Stile base per il form */
.form-dona {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 500px; /* Adatta a seconda delle tue necessità */
    margin: auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .input-group {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="number"],
  select {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  input::placeholder {
    color: #aaa;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    background-color: #78c3e0;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  